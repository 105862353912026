import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import { GlobalLayout, WorkLayout } from 'components/Layout';
import { Banner, Images, Header } from 'components/Works';

function Tutu() {
  return (
    <GlobalLayout>
      <WorkLayout>
        <Banner
          render={({ onLoad, onStartLoad }) => (
            <StaticImage
              placeholder='none'
              onStartLoad={onStartLoad}
              onLoad={onLoad}
              alt='Desktop main page'
              src='../../images/buses.png'
            />
          )}
        />

        <Header title='Tutu Buses' link='https://bus.tutu.ru' />

        <p>
          It started as one of my first large-scale projects back in 2017. At
          the moment Tutu company did not sell bus tickets, but was looking
          forward to expand in this direction. However, as in any business the
          timing matters a lot and building a new team inside the company takes
          time. For that reason, working in a team of outsource developers I was
          offered to create the frontend for the new service called Tutu Buses,
          that would become one of the largest bus tickets aggregating websites
          in Russia.
        </p>
        <h2>The goal</h2>
        <p>
          The main goal was to launch the MVP as soon as possible. The client
          has already prepared the concept and the design of the website, but
          all the development was yet to be done. And despite of the short
          deadline, the quality of the website had to be on point, since there
          would be thousands of real users from the very launch. Another goal
          was to support mobile devices, hence create an adaptive layout, that
          would fit to any screen size. And all of that had to be made in only 2
          months.
        </p>
        <h2>My role</h2>
        <p>
          I was the only frontend developer on that project. But unlike other
          projects, that I have developed before, this time the architecture was
          more important than ever, because of the scale of the project.
          Therefore, it was my responsibility to design a suitable architecture
          and release the frontend part of the website in the required time
          period.
        </p>

        <Images description='Adaptive layout for mobile devices'>
          <StaticImage
            width={300}
            alt='Mobile main page'
            src='../../images/tutu/phone-1.png'
          />
          <StaticImage
            width={300}
            alt='Mobile search results page'
            src='../../images/tutu/phone-2.png'
          />
        </Images>

        <h2>Technologies</h2>

        <p>
          At that era of the frontend development the SPA approach was becoming
          more popular, as it provided a better user experience without page
          reloads and was better in terms of development and maintanance.
        </p>
        <p>
          But for a website that relies hugely on search engines it was crucial
          that the HTML markup would be generated on the server, meaning that
          frameworks such as angular or ember could not be used.
        </p>

        <p>
          However, I did not want to use an old approach with jQuery and after
          some research I've decided to implement Server-Side Rendering with
          React.js, as I've considered it to be the future of the frontend
          development.
        </p>

        <p>
          This was the first time I've used React.js in production and it was
          not easy. But with hard work and many sleepless nights the project was
          launched and it worked out perfectly.
        </p>

        <p>The final frontend stack of the project looked like this:</p>

        <ul>
          <li>React.js with Server-Side Rendering as a core</li>
          <li>CSS modules combined with Stylus for styling</li>
          <li>Redux for state management</li>
          <li>Docker for deploying to production</li>
        </ul>

        <p>
          At the moment the project was launched that was the most modern stack
          possible and after a few years it almost became a standard for
          frontend development.
        </p>

        <h2>Result</h2>
        <p>
          The project was successfully released on time and after small
          enhancements and bug fixes handed over to the client's core team for
          further development.
        </p>
      </WorkLayout>
    </GlobalLayout>
  );
}

export default Tutu;
